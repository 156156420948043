<template>
  <v-card id="promotion-list">
    <!-- search -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">
      </div>
      <v-spacer></v-spacer>
    </v-card-text>

    <!-- table -->
    <v-data-table
      :headers="headers"
      :items="roles"
      :loading="loading"
      class="text-no-wrap"
    >
      <!-- name -->
      <template #[`item.name`]="{item}">
        {{ item.name }}
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">

          <!-- edit -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                :disabled="hasNotPermission('Permissions.RoleUpdate')"
                v-bind="attrs"
                v-on="on"
                :to="{ name: 'role-edit', params: { id: item.id } }"
              >
                <v-icon size="18">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Редактирование</span>
          </v-tooltip>

        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { hasPermission } from '@/helpers'

export default {
  name: 'RoleList',
  data: () => ({
    createDialog: false,
    updateDialog: false,
    icons: {
      mdiTrendingUp,
      mdiPlus,
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiDotsVertical,
      mdiEyeOutline,
    },
    deleteMethod: null,
  }),
  computed: {
    ...mapState('role', {
      roles: 'list',
      loading: 'loading',
    }),
    headers() {
      return [
        { text: 'Наименование', value: 'name' },
        {
          text: 'Действия',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    },
  },
  async created() {
    document.title = 'Роли'
    this.fetchRoles();
  },
  methods: {
    ...mapActions('role', {
      fetchRoles: 'fetchList',
    }),
    hasNotPermission(val){
      return !hasPermission(val);
    },
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
